import { ButtonProps } from '@/components/Button';
import { Spinner } from '@/components/Spinner';
import { useKlarnaPayment } from '@/hooks/useKlarnaPayment';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { logger } from '@/utils/logger';
import { mergeOptions } from '@/utils/merge';
import { initPaymentRequest } from 'lib/services/payments';
import Script from 'next/script';
import { useCallback, useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import { KlarnaPayment } from './KlarnaPayment';

export type onPaymentEvent = {
  message?: string;
};
export type SuccessPaymentResponse = {
  token: string;
};

export interface StandaloneKlarnaPaymentProps extends StandaloneComponentProps<typeof KlarnaPayment> {
  paymentContainerId?: string;
  button?: ButtonProps;
  handleSuccess?: (response: SuccessPaymentResponse, e?: onPaymentEvent) => Promise<void>;
  handleFailure?: (e?: onPaymentEvent) => Promise<void>;
}

const defaultPaymentContainerId = 'klarna-payment-container';

export const StandaloneKlarnaPayment: StandaloneComponent<StandaloneKlarnaPaymentProps> = ({
  paymentContainerId,
  handleSuccess,
  handleFailure,
  button,
  options,
  ...props
}) => {
  const { alltIdUserCode } = useUserAuth();
  const { $content, $button, ...$base } = options ?? {};
  const [isLoading, toggleIsLoading] = useToggle(false);
  const isSessionCreated = useRef(false);

  const { init, load, authorize, paymentFormEnabled, klarnaReady } = useKlarnaPayment({
    paymentContainerId: paymentContainerId ?? defaultPaymentContainerId,
  });

  const initiatePaymentSession = useCallback(() => {
    if (klarnaReady && alltIdUserCode && !isSessionCreated.current) {
      isSessionCreated.current = true;
      initPaymentRequest(alltIdUserCode)
        .then((paymentDetails) => {
          if (paymentDetails) {
            init(paymentDetails.client_token);
            load();
          }
        })
        .catch((err: any) => {
          isSessionCreated.current = false;
          logger.error(err);
          handleFailure?.({ message: '* Kan inte skapa betalningssession hos Klarna' });
        });
    }
  }, [handleFailure, init, klarnaReady, load, alltIdUserCode]);

  const authorizePayment = useCallback(async () => {
    if (alltIdUserCode === null) {
      await handleFailure?.({ message: '* Ett fel uppstod vid betalning' });
      return;
    }

    const response = await authorize();

    if (!response.approved) {
      await handleFailure?.({ message: '* Din betalning blev inte godkänd' });
      return;
    }

    await handleSuccess?.({ token: response.authorization_token });
  }, [authorize, handleFailure, handleSuccess, alltIdUserCode]);

  const handlePaymentStart = useCallback(async () => {
    toggleIsLoading();
    try {
      await authorizePayment();
    } catch (err: any) {
      logger.error(err);
      await handleFailure?.({ message: '* Ett fel uppstod vid betalning...' });
      toggleIsLoading();
    }
  }, [authorizePayment, handleFailure, toggleIsLoading]);

  useEffect(() => initiatePaymentSession(), [initiatePaymentSession, klarnaReady, alltIdUserCode]);

  return (
    <KlarnaPayment {...$base} {...props}>
      {alltIdUserCode && (
        <KlarnaPayment.Content {...$content}>
          {!paymentFormEnabled && <Spinner />}
          <div id={paymentContainerId} />
          {paymentFormEnabled && alltIdUserCode && (
            <KlarnaPayment.Button
              {...button}
              options={mergeOptions(
                {
                  disabled: isLoading,
                  onClick: handlePaymentStart,
                  size: 'large',
                } as const,
                $button,
              )}
            />
          )}
        </KlarnaPayment.Content>
      )}
      <Script src="https://x.klarnacdn.net/kp/lib/v1/api.js" strategy="afterInteractive" async />
    </KlarnaPayment>
  );
};
