import { useAppState } from '@/hooks/useAppState';
import useWindowDataLayer from '@/hooks/useWindowDataLayer';
import { createOrderRequest } from 'lib/services/payments';
import { useCallback, useMemo } from 'react';

const useOrderCreate = () => {
  const { push: dataLayerPush } = useWindowDataLayer();
  const [{ device, publishedUrl, pageId, type }] = useAppState();

  const createOrder = useCallback(
    async (userCC: string, authorizationToken: string) => {
      const orderDetails = await createOrderRequest(userCC, authorizationToken, {
        device,
        pageId,
        type,
        publishedUrl,
      });
      const price = orderDetails.orderAmount / 100;
      const { subscriptionId, orderId } = orderDetails;
      dataLayerPush({
        event: 'purchase',
        ecommerce: {
          transaction_id: orderId,
          subscription_id: subscriptionId,
          currency: orderDetails.purchaseCurrency,
          value: price,
          items: [{ price, item_name: orderDetails.purchaseTag, item_id: orderDetails.purchaseTag }],
        },
      });
    },
    [device, publishedUrl, pageId, type, dataLayerPush],
  );

  return useMemo(() => ({ createOrder }), [createOrder]);
};

export default useOrderCreate;
